.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  color: #545454;
  width: 50%;
  height: fit-content;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content-row {
  display: flex;
  align-items: center;
}

.modal-content-row p {
  font-size: 0.75em;
  font-weight: 400;
  word-break: break-word;
  padding-right: 2em;
}

.modal-content-row span {
  font-size: 0.65em;
  font-weight: 400;
  padding-right: 1em;
  cursor: pointer;
}

.modal-text {
  padding: 10px;
}

.copy-button {
  padding: 10px;
  margin-left: 10px;
}
